<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('agent.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <form @submit.prevent="submit">
      <v-row>
        <v-col cols="6" class="border--right">
          <div class="form--group row">
            <label class="col-12 col-lg-4">Uuid Agen</label>
            <div class="col-12 col-lg-8">
              {{ uuid }}
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
            <label class="col-12 col-lg-4">Nama Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="name"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('name') }}</span>
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Email Agen</label>
            <div class="col-12 col-lg-8">
              {{ email }}
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('phone') }">
            <label class="col-12 col-lg-4">Nomor Telepon Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="phone"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('phone') }}</span>
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">{{ $t('agentMember.memberType') }}</label>
            <div class="col-12 col-lg-8">
              {{ membership ? membership.type : '' }}
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">{{ $t('agentMember.memberStatus') }}</label>
            <div class="col-12 col-lg-8">
              {{ membership ? membership.status : '' }}
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">{{ $t('agentMember.memberStart') }}</label>
            <div class="col-12 col-lg-8">
              {{
                membership && membership.start_at
                  ? format(new Date(membership.start_at), 'dd MMMM yyyy HH:mm')
                  : ''
              }}
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">{{ $t('agentMember.availableListingSlot') }}</label>
            <div class="col-12 col-lg-8">
              <span v-if="listingSlot">{{ listingSlot }}</span>
              <span v-else><b>HABIS</b></span>
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">{{ $t('agentMember.coinAmount') }}</label>
            <div class="col-12 col-lg-8">
              <span v-if="coins">{{ coins }}</span>
              <span v-else><b>HABIS</b></span>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="form--group row">
            <label class="col-12 col-lg-4">Tipe Agen</label>
            <div class="col-12 col-lg-8">
              {{ agentType }}
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Kantor Properti</label>
            <div class="col-12 col-lg-8">
              {{ office }}
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Spesialis Properti</label>
            <div class="col-12 col-lg-8">
              <ul>
                <li v-for="(item, index) in propSpecs" :key="`prop-specs-${index}`">
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Kisaran Harga Properti</label>
            <div class="col-12 col-lg-8">
              <ul>
                <li v-for="(item, index) in priceSpecs" :key="`price-specs-${index}`">
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Area Spesialisasi</label>
            <div class="col-12 col-lg-8">
              <ul>
                <li v-for="(item, index) in areaSpecs" :key="`area-specs-${index}`">
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-6">
            {{ $t('general.bankInfo') }}
          </div>
          <hr class="mt-2 mb-4" />
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('selectedBank') }"
          >
            <label class="col-12 col-lg-4">Nama Bank</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="selectedBank"
                id="selectedBankField"
                v-model="selectedBank"
                :options="banks"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
                :disabled="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('selectedBank') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('accountNumber') }"
          >
            <label class="col-12 col-lg-4">Nomor Rekening</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="accountNumber"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('accountNumber') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('accountHolder') }"
          >
            <label class="col-12 col-lg-4">Nama Pemilik Rekening</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="accountHolder"
                :readonly="!isEditing"
              />
              <span class="val-error">{{ validation.firstError('accountHolder') }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </form>
    <!--    <div-->
    <!--      class="d-flex align-center justify-center"-->
    <!--      style="margin-top: 32px"-->
    <!--      v-if="permissions.includes('EDIT')"-->
    <!--    >-->
    <!--      <v-btn color="primary" class="bottom&#45;&#45;button" @click="startEdit" v-if="!isEditing">-->
    <!--        {{ $t('agent.btn.startEdit') }}-->
    <!--      </v-btn>-->
    <!--      <v-btn color="primary" class="bottom&#45;&#45;button" @click="submit" v-else>-->
    <!--        {{ $t('agent.btn.save') }}-->
    <!--      </v-btn>-->
    <!--    </div>-->
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: { Multiselect },
  data() {
    return {
      format,
      isEditing: false,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      uuid: (state) => state.agentMember.form.uuid,
      banks: (state) => state.agentMember.form.banks,
      email: (state) => state.agentMember.form.email,
      membership: (state) => state.agentMember.form.membership,
      agentType: (state) => state.agentMember.form.agentType,
      office: (state) => state.agentMember.form.office,
      areaSpecs: (state) => state.agentMember.form.areaSpecs,
      propSpecs: (state) => state.agentMember.form.propSpecs,
      priceSpecs: (state) => state.agentMember.form.priceSpecs,
      listingSlot: (state) => state.listing.listingSlot,
      coins: (state) => state.listing.coins,
    }),
    name: {
      get() {
        return this.$store.state.agentMember.form.name;
      },
      set(value) {
        this.$store.commit('agentMember/form/SET_NAME', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.agentMember.form.phone;
      },
      set(value) {
        this.$store.commit('agentMember/form/SET_PHONE', value);
      },
    },
    selectedBank: {
      get() {
        return this.$store.state.agentMember.form.selectedBank;
      },
      set(value) {
        this.$store.commit('agentMember/form/SET_SELECTED_BANK', value);
      },
    },
    accountHolder: {
      get() {
        return this.$store.state.agentMember.form.accountHolder;
      },
      set(value) {
        this.$store.commit('agentMember/form/SET_ACCOUNT_HOLDER', value);
      },
    },
    accountNumber: {
      get() {
        return this.$store.state.agentMember.form.accountNumber;
      },
      set(value) {
        this.$store.commit('agentMember/form/SET_ACCOUNT_NUMBER', value);
      },
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agent.name.required'));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agent.phone.required'));
    },
  },
  methods: {
    async deleteAction(id) {
      try {
        await this.$store.dispatch('agentMember/delete', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agent.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    startEdit() {
      this.isEditing = true;
      this.$store.commit('agentMember/form/postData');
    },
    async submit() {
      if (await this.$validate()) {
        await this.$store.dispatch('agentMember/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agent.update.successMsg'),
          'success',
        );
        this.isEditing = false;
        await this.$store.dispatch('agentMember/getDetailInitData', this.$route.params.uuid);
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
