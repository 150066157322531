<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.belirumaUser.list')"
    class="px-5 py-3"
  >
    <div class="d-flex align-items-center justify-content-between">
      <v-btn @click="exportData" color="success"> Export </v-btn>
      <div class="d-flex align-items-center justify-content-end">
        <base-date-filter
          @changeFilter="changeDateFilter"
          placeholder="Periode Waktu"
          style="flex: 0 0 46%; max-width: 46%; margin-top: 0"
        >
        </base-date-filter>
        <div style="width: 12%"></div>
        <base-dropdown-filter
          :items="agentOfficeFilters"
          @changeFilter="changeAgentOfficeFilter"
          style="flex: 0 0 25%; max-width: 25%; margin-top: 0"
          label="Filter Kantor Properti"
        >
        </base-dropdown-filter>
        <div style="width: 12%"></div>
        <base-dropdown-filter
          :items="salesFilters"
          @changeFilter="changeSalesFilter"
          style="flex: 0 0 25%; max-width: 25%; margin-top: 0"
          label="Siapa Salesnya"
        >
        </base-dropdown-filter>
      </div>
    </div>
    <div style="position: relative">
      <base-add-button
        :permissions="permissions"
        :basePath="basePath"
        :title="$t('belirumaUser.add')"
        style="position: absolute; top: -70px; right: 0"
      >
      </base-add-button>
    </div>
    <div class="d-flex align-items-center justify-space-between mt-2">
      <div>{{ $t('belirumaUser.viewTotal') }}: {{ meta.total }}</div>
      <base-dropdown-multiple-filter
        :items="cities"
        :itemTitle="'name'"
        :itemValue="'id'"
        @changeFilter="changeCities"
        class="mr-4"
        label="Filter Area Spesialisasi"
      >
      </base-dropdown-multiple-filter>
    </div>
    <!--    <div class="d-flex" style="margin-top: -46px">-->
    <!--      <div style="flex: 0 0 50%; max-width: 50%;"></div>-->
    <!--      <base-dropdown-filter-->
    <!--        :items="otpFilters"-->
    <!--        @changeFilter="changeOtpFilter"-->
    <!--        style="flex: 0 0 25%; max-width: 25%;"-->
    <!--        label="Status Verifikasi Telepon"-->
    <!--      >-->
    <!--      </base-dropdown-filter>-->
    <!--      <base-dropdown-filter-->
    <!--        :items="identityFilters"-->
    <!--        @changeFilter="changeIdentityFilter"-->
    <!--        style="flex: 0 0 25%; max-width: 25%;"-->
    <!--        label="Status Verifikasi Identitas"-->
    <!--      >-->
    <!--      </base-dropdown-filter>-->
    <!--    </div>-->
    <!--    <div style="height: 46px"></div>-->
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
          <th class="primary--text">
            {{ $t('agent.uuid') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.name') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.phone') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.email') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.salesName') }}
          </th>
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            {{ $t('belirumaUser.phoneVerifStatus') }}-->
          <!--          </th>-->
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            {{ $t('belirumaUser.identityVerifStatus') }}-->
          <!--          </th>-->
          <th class="primary--text">
            {{ $t('agentMember.agentType') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.agentOffice') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.memberType') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.memberStatus') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.availableListingSlot') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.pastListingAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.coinAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.memberStart') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.memberEnd') }}
          </th>
          <th class="primary--text" v-if="hasDateFilter">
            {{ $t('agentMember.activationAmount') }}
          </th>
          <th class="primary--text" v-if="hasDateFilter">
            {{ $t('agentMember.repostAmount') }}
          </th>
          <th class="primary--text" v-if="hasDateFilter">
            {{ $t('agentMember.visitAmount') }}
          </th>
          <th class="primary--text" v-if="hasDateFilter">
            {{ $t('agentMember.impressionAmount') }}
          </th>
          <th class="primary--text" v-if="hasDateFilter">
            {{ $t('agentMember.waLeadsAmount') }}
          </th>
          <th class="primary--text" v-if="hasDateFilter">
            {{ $t('agentMember.contactAmount') }}
          </th>
          <th class="primary--text" v-if="hasDateFilter">
            {{ $t('agentMember.nominalAmount') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`agent-${index}`">
          <td>{{ index + 1 }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.uuid"
            ></base-detail-button>
          </td>
          <td>{{ item.uuid }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.sales_name }}</td>
          <td>{{ item.agent_type }}</td>
          <td>{{ item.agent_office }}</td>
          <!--          <td>{{ item.phone_verif_status }}</td>-->
          <!--          <td>{{ item.identity_verif_status }}</td>-->
          <td>{{ item.membership_type }}</td>
          <td>{{ item.membership_status }}</td>
          <td>{{ `${item.used_listing_slot} / ${item.total_listing_slot}` }}</td>
          <td>{{ item.past_listing_amount }}</td>
          <td>{{ item.coin_amount }}</td>
          <td>
            {{
              item.membership_start_at
                ? format(new Date(item.membership_start_at), 'dd MMMM yyyy HH:mm')
                : ''
            }}
          </td>
          <td>
            {{
              item.membership_end_at
                ? format(new Date(item.membership_end_at), 'dd MMMM yyyy HH:mm')
                : ''
            }}
          </td>
          <td v-if="hasDateFilter">{{ item.activation_amount }}</td>
          <td v-if="hasDateFilter">{{ item.repost_amount }}</td>
          <td v-if="hasDateFilter">{{ item.visit_amount }}</td>
          <td v-if="hasDateFilter">{{ item.impression_amount }}</td>
          <td v-if="hasDateFilter">{{ item.wa_leads_amount }}</td>
          <td v-if="hasDateFilter">{{ item.contact_amount }}</td>
          <td v-if="hasDateFilter">
            {{ item.nominal_amount ? currencyFormat(item.nominal_amount) : '' }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'cities'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
      minDateFilter: null,
      maxDateFilter: null,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.belirumaUser.basePath,
      otpFilters: (state) => state.belirumaUser.otpFilters,
      identityFilters: (state) => state.belirumaUser.identityFilters,
      agentOfficeFilters: (state) => state.belirumaUser.agentOfficeFilters,
      salesFilters: (state) => state.belirumaUser.salesFilters,
    }),
    hasDateFilter() {
      return this.minDateFilter !== null && this.maxDateFilter !== null;
    },
  },
  methods: {
    async exportData() {
      let query = this.$route.query;
      await this.$store.dispatch('belirumaUser/export', query);
    },
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeOtpFilter(val) {
      this.$emit('changeOtpFilter', val);
    },
    changeIdentityFilter(val) {
      this.$emit('changeIdentityFilter', val);
    },
    changeCities(val) {
      this.$emit('changeCities', val);
    },
    changeAgentOfficeFilter(val) {
      this.$emit('changeAgentOfficeFilter', val);
    },
    changeSalesFilter(val) {
      this.$emit('changeSalesFilter', val);
    },
    changeDateFilter(val) {
      this.$emit('changeDateFilter', val);
      this.minDateFilter = val ? val[0] : null;
      this.maxDateFilter = val ? val[1] : null;
    },
  },
};
</script>
